/* Shared styles */

ion-footer {
  box-shadow: 0 -6px 4px rgba(0, 0, 0, 0.05);

  &:before {
    background: none !important;
  }

  ion-toolbar {
    padding-bottom: calc((var(--safe-area-inset-bottom)) / 2) !important;
  }
}

.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 20px !important;
}

.swiper-pagination-bullet-active {
  background: #F26A20 !important;
}

.swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background: #fff;
  opacity: 1;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  --progress-bar-background-active: #F26A20 !important; 
}

.swiper-container-horizontal>.swiper-pagination-progressbar, 
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  bottom: 0;
  top: unset !important;
}


.show-more-dir {
  text-transform: uppercase;
  color: #F26A20;
  font-weight: 600;
  font-size: 0.85em;
  margin: 0 3px;

  &__new-line {
    display: inherit;
    margin: 10px 3px;

    &::before {
      content: '+';
      margin-right: 3px;
    }

    &__less {
      &::before {
        content: '-';
        margin-right: 3px;
      }
    }
  }
}

.html-cotent {

  ul,
  ol {
    padding: 0 25px;
    list-style-image: url('~src/assets/imgs/n2-check-pu.svg');

    li {
      padding: 0 5px 10px;
    }
  }
}

sticky-loader {
  +* {
    transition: opacity ease-in 0.3s;
  }

  &.loading+* {
    opacity: 0;
  }
}

.detail-address {
  .detail-meta {
    p {
      margin: 0 auto !important;
    }
  }
}

.auth-popup,
.gift-card-modal {
  .modal-wrapper {
    width: 300px;
    height: 375px;
    border-radius: 5px;
  }
}

.swiper-pagination.swiper-pagination-fraction {
  font-size: 13px;
  color: #fff;
  padding: 0 15px;
  direction: ltr;
  text-align: right;
}

html {
  &[dir="rtl"] {
    .swiper-pagination.swiper-pagination-fraction {
      text-align: left;
    }
  }
}

ion-loading {
  ion-spinner {
    width: 40px;
    height: 40px;
  }
}

.discover-loader {
  .loading-wrapper {
    --background: transparent;
    box-shadow: none;
  }
}

.funz-divider {
  width: 100%;
  height: 1px;
  background: #E0E0E0;
}

.rounded-iphone {
  .can-be-rounded {
    border-radius: 25px !important;
  }
}

.ripple-light-purple {
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: #F9EBFA radial-gradient(circle, transparent 1%, #F9EBFA 1%) center/15000%;
  }

  &:active {
    background-color: #f3d7f5;
    background-size: 100%;
    transition: background 0s;
  }
}

.ripple-purple {
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: #612265 radial-gradient(circle, transparent 1%, #612265 1%) center/15000%;
  }

  &:active {
    background-color: #4e1b51;
    background-size: 100%;
    transition: background 0s;
  }
}

.share-modal-view .modal-wrapper {
  height: 210px !important;
  width: 100% !important;
  bottom: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  background: transparent;
}

.location-selector .modal-wrapper {
  width: 330px;
  height: 450px;
  border-radius: 10px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.14);
}

.order-wait-selector .modal-wrapper {
  width: 330px;
  height: 320px;
  border-radius: 10px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.14);
}

.rerequest-location-access .modal-wrapper {
  width: 330px;
  height: 380px;
  border-radius: 10px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.14);
}

.general-alert-modal .modal-wrapper {
  width: calc(100% - 40px);
  height: calc(100% - 25vh);
  background: #fff;
  border-radius: 6px;
  position: absolute;
  overflow: hidden;
  border: 5px solid #7D4281;

}

.donation-popup .modal-wrapper {
  width: 270px;
  height: 330px;
  top: 10vh;
  border-radius: 5px;
}

.event-availability .modal-wrapper {
  top: calc((100vh - 410px) / 3);
  height: 410px;
  width: 320px;
  border-radius: 5px;
}

.event-orderable .modal-wrapper {
  top: calc((100vh - 320px) / 3);
  height: 320px;
  width: 320px;
  border-radius: 5px;
}

countdown {
  .hand {
    display: inline-block;
    padding: 0 2px;
    margin: 0 1px;
    width: 32px;
    height: 35px;
    line-height: 35px;
    box-sizing: initial;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF8AAAAjCAYAAADyrNZPAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAACxMAAAsTAQCanBgAAAHRSURBVGje7doxihtBEEbhV0NHwjCgRAYfwIMDH8BsugfYK0g6gMFX2NTgA+gsmxi8NzBStKnB0cJKkfovB17JJ+ipAndHAiXva0rFCMY2mw3AO+ArcPP6ec7zC3gEvux2uyeA7XYb2TOb1dbr9Xvgh5ktzSykyN1x92fgE1Cje+ayFne/N7Pl5YvAM7r7PVCT9DS3Fne/NbMUUHe/5e/lp+hpbS2Sxiw/b0lvALL0tLaW1x0U3QL8WzNZelpbiySGYYhuAUASQJqe1tYiKc2kXS4/S09ra187gdYi6Tpx0efSkaWntbWvnUBrv/xAa187gdZ++YHWvnYCrX3yA6198gOtRRKLxSK6BYDj8QiQpqe1tdRaORwO0S0AjOMIkKantbVIYrVaRbcAcDqdANL0tLb2nR9o7U87gdZ++YHWfvmB1n75gdYiifP5HN1yDQLS9LS2FknUWqNbrkFAmp7W1j75gdbi7mkm7fJ8n6WntbVPfqA11T/c/+7tBXd/ljRGxwCY2QtQs/S0thZJD+5+Fz1tZoaZPQA1Q88cVpum6QPwXdIyCmxmDMNweT+f6J65rMXdfwIfzeybmd0Ab2fu+Q08uvvn/X7/BDBNU2TPbNY/RYn/l73uadIAAAAASUVORK5CYII=') no-repeat;
  }

  .digital {
    color: #fff;
    font-size: 28px;
  }
}

.cookie-consent {
  font-size: 12px;
}