/** Ionic CSS Variables **/
:root,
:root[mode=md],
:root[mode=ios] {
  --ion-color-primary: #F26A20;
  --ion-color-success: #27ae60;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

@font-face {
  font-family: "Bree Serif";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/BreeSerif.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Montserrat.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Montserrat-Medium.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/Montserrat-SemiBold.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Montserrat-Bold.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Assistant-Regular.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/Assistant-SemiBold.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Assistant-Bold.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 800;
  src: url(../assets/fonts/Assistant-ExtraBold.woff2) format("woff2");
  font-display: swap;
}